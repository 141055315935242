import { useErrorHandlingStore } from 'src/stores/ErrorHandlingStore'

const errorHandling = (error) => {
  const errorHandlingStore = useErrorHandlingStore()

  if (error?.response?.status === 500 || error?.response?.status === 404) {
    errorHandlingStore.setError(error)
    errorHandlingStore.changeErrorScreenState(true)
  }
}

export { errorHandling }
