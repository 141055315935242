import { api } from 'src/boot/axios.js'

export default class CoursesApi {
  attachments = {
    get: async function (id: number) {
      const { data } = await api.get(`courses/${id}/attachments`)
      return data.data
    },
  }
}
