import { api } from 'src/boot/axios.js'

export default class PostalCodeApi {
  view = {
    get: async function (postalCode: string) {
      const { data } = await api.get(`/postal-code/${postalCode}/view`)
      return data.data
    },
  }
  cities = {
    get: async function (state: string) {
      const { data } = await api.get(`/postal-code/${state}/cities`)
      return data.data
    },
  }
}
