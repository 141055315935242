import { api } from 'src/boot/axios'

export default class AttachmentsApi {
  async get(id: number) {
    const { data } = await api.get(`attachments/${id}`)
    return data.data
  }

  authorized = {
    get: async function () {
      const { data } = await api.get('attachments/authorized')
      return data.data
    },
  }

  start = {
    put: async function (id: number) {
      const { data } = await api.put(`attachments/${id}/start`)
      return data.data
    },
  }

  end = {
    put: async function (id: number) {
      const { data } = await api.put(`attachments/${id}/end`)
      return data.data
    },
  }
}
