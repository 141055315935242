import axios from 'axios'
import moment from 'moment'
import { boot } from 'quasar/wrappers'

const getToken = () => {
  let authStoreLocalStorage = JSON.parse(localStorage.getItem('authStore'))
  let token = null

  if (authStoreLocalStorage) {
    token = authStoreLocalStorage.token
  }

  // Configuration for the app (temporarily)
  if (localStorage.getItem('token')) {
    token = localStorage.getItem('token')

    if (token === null || token === 'null') {
      token = ''

      localStorage.setItem('token', token)

      return token
    }

    if (!authStoreLocalStorage) {
      authStoreLocalStorage = {}
    }

    authStoreLocalStorage.token = token
    authStoreLocalStorage.expires_at = moment()
      .add(259200, 'seconds')
      .format('YYYY-MM-DD HH:mm:ss')

    localStorage.setItem('authStore', JSON.stringify(authStoreLocalStorage))
  }
  // Configuration for the app (temporarily)

  return token
}

const token = getToken()

const api = axios.create({
  baseURL: process.env.APIV1_URL,
  timeout: 30000,
  headers: {
    'x-access-token': token,
    'x-user-environment': 'WEB',
  },
})

const apiV2 = axios.create({
  baseURL: process.env.APIV2_URL,
  timeout: 30000,
  headers: {
    'x-access-token': token,
    'x-user-environment': 'WEB',
  },
})

const apiHub = axios.create({
  baseURL: process.env.API_HUB_URL,
  timeout: 30000,
  headers: {
    'Authorization': token,
    'x-user-environment': 'WEB',
  },
})

apiHub.interceptors.request.use((config) => {
  config.headers.Authorization = getToken()
  return config
})

function is404Error(error, router) {
  const authStoreLocalStorage = JSON.parse(localStorage.getItem('authStore'))
  const currentPath = router.currentRoute.value.path
  const publicRoutes = ['/login', '/requestResetPassword', '/resetPassword']
  const isTheCurrentPathPublic = publicRoutes.includes(currentPath)

  if (
    error &&
    error.response &&
    error.response.status === 401 &&
    !isTheCurrentPathPublic
  ) {
    authStoreLocalStorage.token = null
    authStoreLocalStorage.expires_at = null
    localStorage.setItem('authStore', JSON.stringify(authStoreLocalStorage))

    // Configuration for the app (temporarily)
    localStorage.setItem('token', '')
    // Configuration for the app (temporarily)

    return true
  }

  return false
}

export default boot(async ({ app, router, redirect }) => {
  app.config.globalProperties.$axios = axios
  app.config.globalProperties.$api = api
  app.config.globalProperties.$apiv2 = apiV2

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (is404Error(error, router)) {
        return redirect('/login')
      }

      return Promise.reject(error)
    },
  )

  apiV2.interceptors.response.use(
    (response) => response,
    (error) => {
      if (is404Error(error, router)) {
        return redirect('/login')
      }

      return Promise.reject(error)
    },
  )
})

export { api, apiV2, apiHub }
