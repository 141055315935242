import { defineStore } from 'pinia'

export const useErrorHandlingStore = defineStore('errorHandlingStore', {
  state: () => ({
    showErrorScreen: false,
    error: {},
  }),

  actions: {
    changeErrorScreenState(state) {
      this.showErrorScreen = state
    },

    setError(error) {
      this.error = error
    },
  },
})
