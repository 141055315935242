import LmsApi from './lms'
import PlatformsApi from './platforms'
import ShortVideosApi from './shortVideos'
import UsersApi from './users'
import AuthApi from './auth'
import AttachmentsApi from './attachments'
import CoursesApi from './courses'
import ModulesApi from './modules'
import PostalCodeApi from './postalCode'

export default {
  lms: new LmsApi(),
  platforms: new PlatformsApi(),
  shortVideos: new ShortVideosApi(),
  users: new UsersApi(),
  auth: new AuthApi(),
  attachments: new AttachmentsApi(),
  courses: new CoursesApi(),
  modules: new ModulesApi(),
  postalCode: new PostalCodeApi(),
}
