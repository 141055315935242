<template>
  <div></div>
</template>
<script setup lang="ts">
import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { Loading } from 'quasar'

import { useLoadingStore, LoadingTypeEnum } from 'src/stores/loading'

const loadingScreenStore = useLoadingStore()
const { loadingParams } = storeToRefs(loadingScreenStore)

function setLoading() {
  if (loadingParams.value.type === LoadingTypeEnum.FULLPAGE) {
    if (loadingParams.value.loading) {
      Loading.show()
      return
    }
    Loading.hide()
  }
}

watch(loadingParams, setLoading, { deep: true })
</script>
