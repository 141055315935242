import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'
import { setCssVar } from 'quasar'

import { Platform, Theme } from 'src/api/platforms'

const platformStoreData = localStorage.getItem('platformStore')

export const usePlatformStore = defineStore('platformStore', () => {
  const platform = ref<Platform | undefined>(
    platformStoreData ? JSON.parse(platformStoreData) : undefined,
  )

  const theme = computed(() => platform.value?.theme)

  const isTemplateDefault = computed(() => theme.value?.template === 'default')

  const isTemplateStreaming = computed(
    () => theme.value?.template === 'streaming',
  )

  function clear() {
    platform.value = undefined
    localStorage.removeItem('platformStore')
  }

  function set() {
    if (!platform.value) return

    localStorage.setItem('platformStore', JSON.stringify(platform.value))

    if (platform.value?.theme) {
      setColors(platform.value.theme.palette)
    }
  }

  function setColors(palette: Theme['palette']) {
    setCssVar('primary', palette.primary.main)
    setCssVar('secondary', palette.secondary.main)
  }

  watch(platform, set, { deep: true })

  return {
    platform,
    theme,
    isTemplateDefault,
    isTemplateStreaming,
    clear,
    set,
  }
})
