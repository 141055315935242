import { Notify } from 'quasar'
import api from 'src/api'

export async function claritYGTAGGA() {

  try {
    const data = await api.lms.settings.get([
      'clarity_key',
      //'gtag_manager_id',
      //'ga_tracking_id',
    ])

    //const [clarity, gtm, ga] = data

    const [clarity] = data

    if (clarity?.value) {
      const script = document.createElement('script')
      const scriptContent = document.createTextNode(`
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "${clarity.value}");
            `)

      script.appendChild(scriptContent)
      document.head.appendChild(script)
    }
  }catch (error) {
    Notify.create({
      message: "Um erro inesperado ocorreu.",
      color: 'negative',
      position: 'top',
      icon: 'error',
    })
  }

  /*
  if (gtm?.value) {
    const gtmScriptSrc = `https://www.googletagmanager.com/gtag/js?id=${gtm.value}`
    const scriptSrc = document.createElement('script')
    scriptSrc.setAttribute('src', gtmScriptSrc)
    scriptSrc.setAttribute('async', 'true')
    document.head.appendChild(scriptSrc)

    const gtmScriptBody = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${gtm.value}');`

    const scriptBody = document.createElement('script')
    scriptBody.textContent = gtmScriptBody
    document.head.appendChild(scriptBody)
  }

  if (ga?.value) {
    const gaScriptSrc = `https://www.googletagmanager.com/gtag/js?id=${ga.value}`
    const gaScriptBody = `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "${ga.value}");
          `

    const scriptSrc = document.createElement('script')
    scriptSrc.setAttribute('src', gaScriptSrc)
    scriptSrc.setAttribute('async', 'true')
    document.head.appendChild(scriptSrc)

    const scriptBody = document.createElement('script')
    scriptBody.textContent = gaScriptBody
    document.head.appendChild(scriptBody)
  }
  */
}

export async function IMBotScript() {
  const mobile = localStorage.getItem('hideHeaderAndBottomMenu')

  if (mobile) return

  try{

    const [data] = await api.lms.settings.get(['imbot_key'])

    const imbotKey = data.value

    if (!imbotKey) return

    const scriptBody = `(() => {
      window.icwToken = "${imbotKey}";
      const script = document.createElement("script");
      script.src = "https://app.imbot.com.br:8090/main.js";
      script.id = "imbot-script"
      document.head.appendChild(script);
    })();`

    const createScriptBody = document.createElement('script')
    createScriptBody.textContent = scriptBody
    document.body.appendChild(createScriptBody)

  }catch (error) {
    Notify.create({
      message: "Um erro inesperado ocorreu.",
      color: 'negative',
      position: 'top',
      icon: 'error',
    })
  }
}

export function IMBotSecretariaEducacao() {
  const imbotScript = document.getElementById(
    'imbot-script-secretaria-educacao',
  )

  if (imbotScript) {
    showIMBotscript()
    return
  }

  const scriptBody = `(() => {
      window.icwToken = "88abccb4-29bf-4149-bd6d-180f9392a1f5";
      const script = document.createElement("script");
      script.src = "https://imbot.ibasi.com.br:555/main.js";
      script.id = "imbot-script-secretaria-educacao"
      document.head.appendChild(script);
    })();`

  const createScriptBody = document.createElement('script')
  createScriptBody.textContent = scriptBody
  document.body.appendChild(createScriptBody)
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export function hideIMBotscript() {
  const smallIMBotModal = document.querySelector('.css-zg7h8') as any
  const bigIMBotModal = document.querySelector('.css-wqumdr') as any

  if (smallIMBotModal) smallIMBotModal.style = 'display: none'
  if (bigIMBotModal) bigIMBotModal.style = 'display: none'
}

export function showIMBotscript() {
  const smallIMBotModal = document.querySelector('.css-zg7h8') as any
  const bigIMBotModal = document.querySelector('.css-wqumdr') as any

  if (smallIMBotModal) smallIMBotModal.style = 'display: fixed'
  if (bigIMBotModal) bigIMBotModal.style = 'display: fixed'
}
/* eslint-enable @typescript-eslint/no-explicit-any */