<template>
  <!-- Press Ctrl + shift + l -->
  <q-dialog v-model="modelValue">
    <div v-if="theme" class="modal-settings">
      <div>
        <q-btn v-close-popup icon="close" flat round dense />
      </div>

      <q-select
        v-model="theme.template"
        label="template"
        outlined
        dense
        :options="['default', 'streaming']"
        class="q-mt-md"
      />

      <div class="modal-settings-buttons q-mt-md">
        <div
          class="dark-mode-section platform__animation__scale_1-05"
          :class="{ actived: is_dark_mode }"
          @click="toggleDarkMode()"
        >
          <q-icon :name="is_dark_mode ? 'light_mode' : 'dark_mode'" />

          <div>{{ is_dark_mode ? $t('lightMode') : $t('darkMode') }}</div>
        </div>
      </div>
    </div>
  </q-dialog>
</template>
<script setup lang="ts">
import { usePlatformStore } from 'src/stores/platform'
import { usePlatformSettingsStore } from 'src/stores/PlatformSettingsStore'

import { storeToRefs } from 'pinia'

const modelValue = defineModel<boolean>()

const platformStore = usePlatformStore()
const platformSettingsStore = usePlatformSettingsStore()

const { theme } = storeToRefs(platformStore)

const { is_dark_mode } = storeToRefs(platformSettingsStore)

function toggleDarkMode() {
  if (is_dark_mode.value) {
    platformSettingsStore.setDarkMode(false)
    return
  }

  platformSettingsStore.setDarkMode(true)
}
</script>

<style scoped lang="scss">
.modal-settings {
  background-color: #fff;
  max-width: 300px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
  padding: 20px;
}

.modal-settings-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 250px;

  .dark-mode-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #292929;
    border-radius: 10px;
    width: 120px;
    height: 48px;
    border: 1px solid #dadada;
    color: #fff;
    cursor: pointer;

    &.actived {
      background: #fff;
      color: #000;
    }
  }

  .libras-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    width: 120px;
    height: 48px;
    border: 1px solid #dadada;
    cursor: pointer;
  }
}
</style>
