import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum LoadingTypeEnum {
  FULLPAGE = 'FULLPAGE',
}

export interface LoadingParams {
  loading: boolean
  type: LoadingTypeEnum
}

export const useLoadingStore = defineStore('loadingStore', () => {
  const loadingParams = ref<LoadingParams>({
    loading: false,
    type: LoadingTypeEnum.FULLPAGE,
  })

  return {
    loadingParams,
  }
})
