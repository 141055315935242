import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    name: 'home',
    path: '/',
    component: () => import('pages/HomePage/index.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'errorNotFound',
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },

  {
    name: 'login',
    path: '/login',
    component: () => import('pages/LoginPage.vue'),
  },

  {
    name: 'courses',
    path: '/courses',
    component: () => import('pages/CoursesPage/index.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'course',
    path: '/courses/:course',
    component: () => import('pages/CoursePage/index.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'courseModule',
    path: '/courses/:course/modules/:module',
    component: () => import('pages/CourseModulePage/index.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'courseView',
    path: '/courses/:course/modules/:module/learningObjects/:learningObject',
    component: () => import('pages/CourseViewPage/index.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'resetPassword',
    path: '/resetPassword',
    component: () => import('pages/ResetPasswordPage.vue')
  },

  {
    name: 'requestResetPassword',
    path: '/requestResetPassword',
    component: () => import('pages/RequestResetPasswordPage.vue'),
  },

  {
    name: 'grade',
    path: '/grades',
    component: () => import('pages/GradesPage/index.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'attachment',
    path: '/attachment/:attachment',
    component: () => import('pages/AttachmentPage.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'attachments',
    path: '/attachments',
    component: () => import('pages/AttachmentsPage.vue'),
    meta: { requiresAuth: true },
  },

  {
    name: 'debug',
    path: '/debug-feZStGCW',
    component: () => import('pages/DebugPage.vue'),
    meta: { requiresAuth: true },
  },
]

export default routes
