import { api } from 'src/boot/axios.js'

export interface Theme {
  palette: {
    primary: {
      light: string
      main: string
      dark: string
      contrastText: string
      mainGradient: string
    }
    secondary: {
      main: string
    }
  }
  icons: {
    logo: string
    favicon: string
    backgroundImage: string
    appImage: string
  }
  backgroundCertificateImage: string
  template: 'streaming' | 'default'
}

export interface Options {
  username_change_allowed: boolean
  privacy_policy: string
  cookies_policy: string
  terms_of_use: string
}

export interface Platform {
  id: number
  name: string
  is_external_platform: boolean
  external_api_url: boolean
  theme: Theme
  options: Options
}

export interface Setting {
  id: number
  code: string
  referer: string
  name: string
  theme: Theme
  whatsapp_phone_number: string
  is_support_material_download_enabled: boolean
  username_change_allowed: boolean
  pre_enrollment_enabled: boolean
  app_enabled: boolean
  store_enabled: boolean
  store_url: string
}

export default class PlatformsApi {
  async get(siteUrl: string): Promise<Platform> {
    const { data } = await api.get(`/platforms/${siteUrl}`)
    return data.data
  }

  settings = {
    async get(platformId: number): Promise<Setting> {
      const { data } = await api.get(`/platforms/${platformId}/settings`)
      return data.data
    },
  }
}
