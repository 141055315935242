import { api } from 'src/boot/axios.js'

export interface ShortVideo {
  id: number
  name: string
  resource: string
  started_at: number | null
  ended_at: number | null
  video_data: {
    link: string
    id: number
    name: string
    embedPath: string
    thumbnailPath: string
    previewPath: string
    fileUrl: string
    fileDownloadUrl: string
    video_platform: string
  }
}
export default class ShortVideosApi {
  async get(): Promise<ShortVideo[]> {
    const { data } = await api.get('/shortVideos')
    return data.data
  }

  start = {
    post: async function (shortVideoId: number): Promise<ShortVideo> {
      const { data } = await api.post(`/shortVideos/${shortVideoId}/start`)
      return data.data
    },
  }

  end = {
    post: async function (shortVideoId: number): Promise<ShortVideo> {
      const { data } = await api.post(`/shortVideos/${shortVideoId}/end`)
      return data.data
    },
  }
}
