import { defineStore } from 'pinia'
import { Dark } from 'quasar'

export const usePlatformSettingsStore = defineStore('platformSettingsStore', {
  state: () => ({
    is_dark_mode: false,
    font_size: 1,
    font_spacing: 1,
    language: 'pt-BR',
    volume_of_sounds: 0.6,
    is_libras_enabled: false,
  }),

  getters: {
    getPlatformSettings() {
      return {
        is_dark_mode: this.is_dark_mode,
        font_size: this.font_size,
        font_spacing: this.font_spacing,
        language: this.language,
        volume_of_sounds: this.volume_of_sounds,
        is_libras_enabled: this.is_libras_enabled,
      }
    },
  },

  actions: {
    setPlatformSettings(settings) {
      if (settings.is_dark_mode !== undefined) {
        this.is_dark_mode = settings.is_dark_mode
      }

      if (settings.font_size !== undefined) {
        this.font_size = settings.font_size
      }

      if (settings.font_spacing !== undefined) {
        this.font_spacing = settings.font_spacing
      }

      if (settings.language !== undefined) {
        this.language = settings.language
      }

      if (settings.volume_of_sounds !== undefined) {
        this.volume_of_sounds = settings.volume_of_sounds
      }

      if (settings.is_libras_enabled !== undefined) {
        this.is_libras_enabled = settings.is_libras_enabled
      }
    },

    resetPlatformSettings() {
      const defaultSettings = {
        is_dark_mode: false,
        font_size: 1,
        font_spacing: 1,
        language: 'pt-BR',
        volume_of_sounds: 0.6,
        is_libras_enabled: false,
      }

      this.setDarkMode(defaultSettings.is_dark_mode)
      this.setFontSize(defaultSettings.font_size)
      this.setFontSpacing(defaultSettings.font_spacing)
      this.setLanguage(defaultSettings.language)
      this.setVolumeOfSounds(defaultSettings.volume_of_sounds)
      this.setLibrasState(defaultSettings.is_libras_enabled)
    },

    initConfig() {
      const platformSettings = this.getPlatformSettings

      this.setDarkMode(platformSettings.is_dark_mode)
      this.setFontSize(platformSettings.font_size)
      this.setFontSpacing(platformSettings.font_spacing)
    },

    setDarkMode(state) {
      Dark.set(state)

      this.setPlatformSettings({ is_dark_mode: state })
    },

    setFontSize(value) {
      const body = document.querySelector('body')
      const allFontSize = ['zoomx1', 'zoomx2', 'zoomx3']

      const fontSize = {
        1: 'zoomx1',
        2: 'zoomx2',
        3: 'zoomx3',
      }

      const selectedFontSizeIndex = allFontSize.indexOf(fontSize[value])

      body.classList.add(fontSize[value])

      allFontSize.splice(selectedFontSizeIndex, selectedFontSizeIndex)
      allFontSize.forEach((specificFontSize) => {
        body.classList.remove(specificFontSize)
      })

      this.setPlatformSettings({ font_size: value })
    },

    setFontSpacing(value) {
      const body = document.querySelector('body')
      const allFontSpacing = ['textx1', 'textx2', 'textx3']

      const fontSpacing = {
        1: 'textx1',
        2: 'textx2',
        3: 'textx3',
      }

      const selectedFontSpacingIndex = allFontSpacing.indexOf(
        fontSpacing[value],
      )

      body.classList.add(fontSpacing[value])

      allFontSpacing.splice(selectedFontSpacingIndex, selectedFontSpacingIndex)
      allFontSpacing.forEach((specificFontSpacing) => {
        body.classList.remove(specificFontSpacing)
      })

      this.setPlatformSettings({ font_spacing: value })
    },

    setLanguage(value) {
      this.setPlatformSettings({ language: value })
    },

    setVolumeOfSounds(value) {
      this.setPlatformSettings({ volume_of_sounds: value })
    },

    setLibrasState(state) {
      if (state) {
        const librasDiv = document.querySelector('.libras-div')

        librasDiv.style.display = 'block'
      }

      if (!state) {
        const librasDiv = document.querySelector('.libras-div')

        librasDiv.style.display = 'none'
      }

      this.setPlatformSettings({ is_libras_enabled: state })
    },
  },

  persist: true,
})
