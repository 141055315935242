import { useUserStore } from 'src/stores/UserStore.js'

import api from 'src/api'

const getUserSelf = async () => {
  const userStore = useUserStore()

  if (userStore.getSavedUser) {
    return userStore.getSavedUser
  }

  const user = await api.users.self.get()
  userStore.saveUser(user)

  return user
}

const updateUserSelf = async (data) => {
  const userStore = useUserStore()
  const user = await api.users.self.put(data)
  userStore.saveUser(user)
  return user
}

const deleteUserSelf = async (deletionReason) => {
  const deleteUserResponse = await api.users.self.delete.post(deletionReason)
  return deleteUserResponse
}

const updateAcceptedLgpd = async () => {
  await api.users.updateAcceptedLgpd.post()
}

const getUserDashboard = async () => {
  const dashboard = await api.users.dashboard.get()
  return dashboard
}

export {
  getUserSelf,
  updateUserSelf,
  deleteUserSelf,
  updateAcceptedLgpd,
  getUserDashboard,
}
