import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

import { Setting } from 'src/api/platforms'

const platformStoreData = localStorage.getItem('platformSettingStore')

export const usePlatformSettingStore = defineStore(
  'platformSettingStore',
  () => {
    const platformSetting = ref<Setting | undefined>(
      platformStoreData ? JSON.parse(platformStoreData) : undefined,
    )

    function clear() {
      platformSetting.value = undefined
      localStorage.removeItem('platformSettingStore')
    }

    function set() {
      if (!platformSetting.value) return

      localStorage.setItem(
        'platformSettingStore',
        JSON.stringify(platformSetting.value),
      )
    }

    watch(platformSetting, set, { deep: true })

    return {
      platformSetting,
      clear,
      set,
    }
  },
)
