<template>
  <div class="isDark">
    <theme-debug-dialog v-model="showThemeDebugDialog" />

    <loading-screen />

    <router-view />

    <error-screen />
  </div>
</template>

<script setup lang="ts">
import { watch, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'

import { api as axiosApi, apiV2 as axiosApiV2 } from 'src/boot/axios'

import api from 'src/api'
import { getSiteUrl } from 'src/services/platform'
import { getUserSelf } from 'src/services/user'
import { errorHandling } from 'src/services/ErrorHandling/index.js'

import { usePlatformStore } from 'src/stores/platform'
import { useLoadingStore } from 'src/stores/loading'
import { useAuthStore } from 'src/stores/AuthStore'
import { usePlatformSettingsStore } from 'src/stores/PlatformSettingsStore'

import LoadingScreen from 'src/components/LoadingScreen.vue'
import ErrorScreen from 'src/components/ErrorScreen.vue'
import ThemeDebugDialog from 'src/components/ThemeDebugDialog.vue'

import { claritYGTAGGA } from 'src/services/InjectScripts'

const loadScreenStore = useLoadingStore()
const { loadingParams } = storeToRefs(loadScreenStore)

const usePlatformSettings = usePlatformSettingsStore()
const authStore = useAuthStore()
const { token } = storeToRefs(authStore)

const platformStore = usePlatformStore()
const { platform } = storeToRefs(platformStore)

const showThemeDebugDialog = ref(false)

async function fetchPlatform() {
  try {
    loadingParams.value.loading = true
    const data = await api.platforms.get(getSiteUrl())
    platform.value = data

    if (platform.value?.is_external_platform) {
      axiosApi.defaults.baseURL = `${platform.value.external_api_url}/api/v1`
      axiosApi.defaults.baseURL = `${platform.value.external_api_url}/api/v1`

      axiosApiV2.defaults.baseURL = `${platform.value.external_api_url}/api/v2`
      axiosApiV2.defaults.baseURL = `${platform.value.external_api_url}/api/v2`
    }

    if (platform.value?.theme?.icons.favicon) {
      const faviconUrl = platform.value.theme.icons.favicon
      const link =
        document.querySelector("link[rel~='icon']") ||
        document.createElement('link')

      link.rel = 'icon'
      link.href = faviconUrl
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  } catch (error) {
    errorHandling(error)
  } finally {
    loadingParams.value.loading = false
  }
}

async function initializeAuthenticatedConfiguration() {
  usePlatformSettings.initConfig()
  checkIfTheLgpdHasBeenAccepted()
  claritYGTAGGA()
  //IMBotScript()
}

async function checkIfTheLgpdHasBeenAccepted() {
  try {
    const currentRoute = window.location.pathname

    if (currentRoute === process.env.PUBLIC_PATH) {
      return
    }

    const data = await getUserSelf()

    if (data.accepted_lgpd) {
      return
    }
  } catch (error) {
    errorHandling(error)
  }
}

onMounted(() => {
  if (
    process.env.NODE_ENV !== 'production' ||
    process.env.APIV1_URL?.includes('homolog')
  ) {
    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'L') {
        showThemeDebugDialog.value = true
      }
    })
  }

  fetchPlatform()

  if (token.value) {
    initializeAuthenticatedConfiguration()
  }
})

watch(
  () => [token.value],
  () => {
    if (token.value) {
      initializeAuthenticatedConfiguration()
    }
  },
)
</script>
