import { api, apiHub } from 'src/boot/axios.js'

export interface User {
  id: number
  erp_user_id: number | null
  platform_name: string
  document: string
  username: string
  phone_number: string
  name: string
  country_code: string
  address_line_1: string | null
  address_line_2: string | null
  neighborhood: string | null
  city_name: string | null
  state_code: string | null
  postal_code: string | null
  email: string
  birthdate: null
  created_at: string
  updated_at: string
  is_news_letter_enabled: boolean
  is_app_notification_enabled: boolean
  is_whatsapp_messaging_enabled: boolean
  accepted_lgpd: boolean
  is_active: boolean
  has_unanswered_survey: boolean
  survey_url: string | null
  franchisee_id: number | null
  campaign_id: number | null
  old_birthdate: number | null
  education_level: number | null
  id_number: number | null
  is_public_school_egress: number
  is_reseller: number | null
  platform_id: number | null
}

export interface UserERP {
  master: {
    id: number
    name: string
  },
  franchise: {
    id: number
    name: string
  },
  student: {
    id: number
    name: string
    dob: string
    gender: 'M' | 'F'
  },
  contract_id: number
  course: {
    id: number
    name: string
    type: 'I' | 'M'
  },
  class_: {
    id: number
    week_day: string
    class_time: string
  }
}

export interface Dashboard {
  courses: {
    total: number
    average_progress: number
    total_certified_completed: number
    total_certified: number
  }
  modules: {
    total_completed: number
    total: number
  }
  lessons: {
    total_completed: number
    total: number
  }
}

export default class UsersApi {
  self = {
    get: async function (): Promise<User> {
      const { data } = await api.get('/users/self')
      return data.data
    },

    put: async function (user: User): Promise<User> {
      const { data } = await api.put('/users/self', user)
      return data.data
    },

    delete: {
      post: async function (deletionReason: string) {
        const { data } = await api.post('/users/self/delete', {
          deletionReason,
        })
        return data.data
      },
    },
  }

  updateAcceptedLgpd = {
    post: async function () {
      const { data } = await api.post('/users/updateAcceptedLgpd')
      return data.data
    },
  }

  dashboard = {
    get: async function (): Promise<Dashboard> {
      const { data } = await api.get('/users/dashboard')
      return data.data
    },
  }
  
  erp = {
    get: async function (): Promise<UserERP> {
      const { data } = await apiHub.get('/security/student/erp/')      
      return data
    },
  }
}
