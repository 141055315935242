import { defineStore } from 'pinia'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    user: null,
  }),

  getters: {
    getSavedUser() {
      return this.user
    },
  },

  actions: {
    saveUser(user) {
      this.user = user
    },

    resetUser() {
      this.user = null
    },
  },

  persist: true,
})
