import { getSiteUrl } from 'src/services/platform/index.js'
import api from 'src/api'

const getToken = async (data) => {
  const country = data.accessCountry === 'Brasil' ? 'BRA' : 'ANG'
  const siteUrl = getSiteUrl()

  const tokenResponse = await api.auth.token.post({
    client_id: 'lms-student-165c0178b00b784c6dc3',
    grant_type: 'password',
    username: `${siteUrl}:${country}:${data.accessCode}`,
    password: data.accessPassword,
    scope: 'lms',
  })

  return tokenResponse
}

const requestResetPassword = async (data) => {
  const country = 'BRA'
  const siteUrl = getSiteUrl()

  await api.auth.resetPassword.post({
    client_id: 'lms-student-165c0178b00b784c6dc3',
    username: `${siteUrl}:${country}:${data.accessCode}`,
    scope: 'lms',
  })
}

const resetPassword = async (data) => {
  const country = 'BRA'
  const siteUrl = getSiteUrl()

  await api.auth.changePassword.post({
    client_id: 'lms-student-165c0178b00b784c6dc3',
    username: `${siteUrl}:${country}:${data.user}`,
    scope: 'lms',
    new_password: data.newPassword,
    code: data.resetCode,
    challenge_response: data.resetCode,
    challenge_type: 'EmailCode',
  })
}

export { getToken, resetPassword, requestResetPassword }
