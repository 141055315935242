import { defineStore } from 'pinia'
import moment from 'moment'

import { api as axiosApi, apiV2 as axiosApiV2 } from 'src/boot/axios'

import { getToken } from 'src/services/login'

import { useUserStore } from 'src/stores/UserStore'

import { usePlatformSettingStore } from './PlatformSetting'
import api from 'src/api'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    token: null,
    expires_at: null,
  }),

  getters: {
    getNextRoute() {
      return this.router.currentRoute.value.query.next_route
        ? this.router.currentRoute.value.query.next_route
        : '/'
    },

    getToken() {
      return this.token
    },
  },

  actions: {
    async login(loginData) {
      try {
        const response = await getToken(loginData);

        this.token = response.access_token
        this.expires_at = this.calculateTokenExpirationDate(response.expires_in)

        this.setHeaderAccessToken(this.token)

        const responseUser = await api.users.self.get();

        const userStore = useUserStore();
        userStore.saveUser(responseUser);

        this.router.push({ path: this.getNextRoute })
      } catch (error) {
        
      }
    },

    logout() {
      const userStore = useUserStore()
      userStore.resetUser()

      const platformSettingStore = usePlatformSettingStore()
      platformSettingStore.clear()

      this.token = null
      this.expires_at = null

      // Configuration for the app (temporarily)
      localStorage.setItem('token', '')
      // Configuration for the app (temporarily)

      this.router.push({ path: '/login' })
    },

    verifyUserLogged() {
      if (this.isTokenExpired()) {
        this.logout()

        return
      }
    },

    isTokenExpired() {
      const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
      const differenceSeconds = moment(this.expires_at).diff(
        currentDate,
        'seconds',
      )

      if (differenceSeconds <= 0) {
        return true
      }

      return false
    },

    setExternalToken(token) {
      this.token = token
      this.expires_at = this.calculateTokenExpirationDate(3600)

      this.setHeaderAccessToken(this.token)
    },

    setHeaderAccessToken(token) {
      axiosApi.defaults.headers['x-access-token'] = token
      axiosApiV2.defaults.headers['x-access-token'] = token
    },

    calculateTokenExpirationDate(expires_in) {
      const expirationDate = moment()
        .add(expires_in, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')

      return expirationDate
    },

    verifyUserAuthenticationInPrivateRoutes(to, from, next) {
      const userInternalToken = this.getToken
      const userExternalToken = to.query.access_token

      // Configuration for the app (temporarily)
      // if (userInternalToken) {
      //   this.verifyUserLogged();
      // }
      // Configuration for the app (temporarily)

      if (userInternalToken && userExternalToken) {
        this.router.push({ path: '/courses' })

        return
      }

      if (!userInternalToken && userExternalToken) {
        this.setExternalToken(userExternalToken)

        this.router.push({ path: '/courses' })

        return
      }

      if (userInternalToken && to.path === '/login') {
        next({ path: '/', replace: true })

        return
      }

      if (!userInternalToken && to.meta.requiresAuth) {
        next({
          path: '/login',
          replace: true,
          query: { next_route: to.fullPath },
        })

        return
      }

      next()
    },
  },

  persist: true,
})
