import { api } from 'src/boot/axios.js'

export default class ModulesApi {
  attachments = {
    get: async function (id: number) {
      const { data } = await api.get(`modules/${id}/attachments`)
      return data.data
    },
  }
}
